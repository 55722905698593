<template>
  <div>
    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-header flex-wrap border-0 pt-6 pb-0">
        <div class="card-title m-0">
          <h3 class="card-label m-0">
            {{ $t('MENU.offers') }}
            <span class="text-muted pt-2 font-size-sm d-block"></span>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <div class="card-body p-0">
          <div class="form-group row">

            <div class="col-lg-6 mb-5">
              <label>{{ $t('offers.name') }}<span class="text-danger">*</span></label>
              <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''" :placeholder="$t('offers.name')"/>
              <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.name[0] }}
                </span>
            </div>


            <div class="col-lg-6 mb-5">
              <label>{{ $t('offers.users_number') }}</label>
              <input type="number" v-model="data.users_number" class="form-control" :class="validation && validation.users_number ? 'is-invalid' : ''"/>
              <span v-if="validation && validation.users_number" class="fv-plugins-message-container invalid-feedback">
                    {{ validation.users_number[0] }}
                </span>
            </div>
<!--            <div class="col-lg-6 mb-5">-->
<!--              <label>{{ $t('offers.percentage') }}<span class="text-danger">*</span></label>-->
<!--              <input type="number" v-model="data.percentage" class="form-control" :class="validation && validation.percentage ? 'is-invalid' : ''"/>-->
<!--              <span v-if="validation && validation.percentage" class="fv-plugins-message-container invalid-feedback">-->
<!--                    {{ validation.percentage[0] }}-->
<!--                </span>-->
<!--            </div>-->

            <div class="col-lg-6 mb-5">
              <label>{{ $t('offers.start_date') }}<span class="text-danger">*</span></label>
              <input type="date" v-model="data.start_date" class="form-control" :class="validation && validation.start_date ? 'is-invalid' : ''" :placeholder="$t('offers.start_date')"/>
              <span v-if="validation && validation.start_date" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.start_date[0] }}
                </span>
            </div>

            <div class="col-lg-6 mb-5">
              <label>{{ $t('offers.end_date') }}<span class="text-danger">*</span></label>
              <input type="date" v-model="data.end_date" class="form-control" :class="validation && validation.end_date ? 'is-invalid' : ''" :placeholder="$t('offers.end_date')"/>
              <span v-if="validation && validation.end_date" class="fv-plugins-message-container invalid-feedback">
                  {{ validation.end_date[0] }}
                </span>
            </div>

<!--            <div class="col-lg-6 mb-5">-->
<!--              <label>{{ $t('offers.plans') }}</label>-->
<!--              <div class="input-group">-->
<!--                <multiselect-->
<!--                    :class="validation && validation.plans ? 'is-invalid' : ''"-->
<!--                    v-model="plans"-->
<!--                    :placeholder="$t('offers.plans')"-->
<!--                    label="name"-->
<!--                    track-by="id"-->
<!--                    :options="plans_list"-->
<!--                    :multiple="true"-->
<!--                    :taggable="false"-->
<!--                    :show-labels="false"-->
<!--                    :show-no-options="false"-->
<!--                    :show-no-results="false"-->
<!--                >-->
<!--                </multiselect>-->
<!--                <span v-if="validation && validation.plans" class="fv-plugins-message-container invalid-feedback">-->
<!--                  {{ validation.plans[0] }}-->
<!--                </span>-->
<!--              </div>-->
<!--            </div>-->
            <div class="col-lg-6 mb-5">
              <label>&ensp;</label>
              <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('status') }}</b-form-checkbox>
            </div>
            <div class="col-lg-6 mb-5">
              <label>{{ $t('offers.notes') }}</label>
              <textarea v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''" :placeholder="$t('plans.notes')"></textarea>
              <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                        {{ validation.notes[0] }}
                    </span>
            </div>

          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <div class="bg-FFB803 repeater p-3 d-flex justify-content-between">
                <h6 class="mt-2">{{ $t('offers.plans') }}</h6>
                <div class="d-flex">
                  <button type="button" class="btn btn-primary" @click="addMoreToRepeater">{{ $t('add_more') }}</button>
                </div>
              </div>
              <div class="table-responsive bg-white pb-5">
                <table class="table table-row-bordered table-custom-padding">
                  <thead>
                  <tr>
                    <th>{{ $t('offers.plan') }}</th>
                    <th>{{ $t('offers.interval') }}</th>
                    <th>{{ $t('offers.percentage') }}</th>
                    <th>{{ $t('offers.free_days') }}</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in offers_plans" :key="'offers_plans_'+index">
                    <td>
                      <select name="plan_id" id="plan_id" v-model="row.plan_id" class="custom-select">
                        <option v-for="row_data in plans_list" :value="row_data.id" :key="row_data.id">{{ row_data.name }}</option>
                      </select>
                    </td>
                    <td>
                      <select name="interval" id="interval" v-model="row.interval" class="custom-select">
                        <option v-for="row_data in interval_list" :value="row_data.id" :key="row_data.id">{{ row_data.title }}</option>
                      </select>
                    </td>
                    <td><input type="number" v-model="row.percentage" class="form-control"/></td>
                    <td><input type="number" v-model="row.free_days" class="form-control"/></td>
                    <td>
                      <v-icon style="color: #dc3545;" class="m-40-4" small  @click="removeFromRepeater(index)">mdi-delete</v-icon>
                    </td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>


          </div>
        </div>
        <div class="card-footer pl-0 pr-0">
          <div class="row">
            <div class="col-lg-6">
              <button type="reset" class="btn btn-primary mr-2" @click="save()">{{ $t('save') }}</button>
              <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "form-offers",
  data() {
    return {
      mainRouteDependency: 'base/dependency',
      mainRoute: 'settings/offers',
      data: {
        name: null,
        users_number: null,
        start_date: null,
        end_date: null,
        is_active: true,
        notes: null,
        // plans: null,
        // percentage: null,
      },

      isEditing: false,
      idEditing: this.$route.params.id ? this.$route.params.id : null,
      validation: null,
      plans: [],
      plans_list: [],
      offers_plans_form: {plan_id: null, interval: null, percentage: null, free_days: null},
      offers_plans: [],
      interval_list: [
        {id: 1, title: '1'},
        // {id: 3, title: '3'},
        // {id: 6, title: '6'},
        {id: 12, title: '12'},
      ],


    };
  },

  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      ApiService.post(this.mainRoute, {
        ...this.data,
        offers_plans: this.offers_plans,
      }).then((response) => {
        this.validation = null;
        this.$successAlert(response.data.message);
        this.$router.push({name: 'offers.index'});
      })
          .catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    update() {
      ApiService.put(this.mainRoute + `/${this.$route.params.id}`, {
        ...this.data,
        offers_plans: this.offers_plans,
      }).then((response) => {
        this.validation = null;
        this.$router.push({name: 'offers.index'});
        this.$successAlert(response.data.message);
      }).catch((error) => {
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      ApiService.get(this.mainRoute + `/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.users_number = response.data.data.users_number;
        // this.data.percentage = response.data.data.percentage;
        this.data.start_date = response.data.data.start_date;
        this.data.end_date = response.data.data.end_date;
        // this.data.plans = response.data.data.plans;
        this.data.is_active = response.data.data.is_active;
        this.data.notes = response.data.data.notes;
        this.offers_plans = response.data.data.offers_plans;

        if (response.data.data.offers_plans && response.data.data.offers_plans.length == 0){
          this.offers_plans = [];
          this.addMoreToRepeater()
        }

      });
    },


    async getPlans() {
      await ApiService.get(`${this.mainRouteDependency}/plans`).then((response) => {
        this.plans_list = response.data.data;

      });
    },

    addMoreToRepeater(){
      this.offers_plans.unshift(this.offers_plans_form);
      this.offers_plans_form = {plan_id: null, interval: null, percentage: null, free_days: null};

    },
    removeFromRepeater(index = 0){
      if (this.offers_plans.length > 1) {
        this.offers_plans.splice(index, 1);
      }
    },

  },


  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.offers"), route: '/subscription/offers'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);

    this.getPlans();

    if (this.idEditing) {
      this.getData();
    }else {
      this.addMoreToRepeater();
    }
  },
};
</script>


